// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconAlertTriangleFilled = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.3412 17.3812L21.1875 16.9612C19.5225 12.4162 17.0925 8.1112 13.9762 4.19245C12.9675 2.9212 11.04 2.9212 10.0312 4.19245C6.91123 8.1112 4.48123 12.4162 2.81623 16.9612L2.66248 17.3812C2.15248 18.7725 3.09748 20.2687 4.57123 20.4112C9.51373 20.8837 14.49 20.8837 19.4325 20.4112C20.9062 20.2687 21.8512 18.7725 21.3412 17.3812ZM12.3187 18.465C11.355 18.6487 10.53 17.82 10.71 16.8562C10.8112 16.3237 11.25 15.885 11.7825 15.7837C12.7462 15.6 13.5712 16.4287 13.3912 17.3925C13.29 17.9287 12.8512 18.3637 12.3187 18.465ZM13.4175 13.0275L13.26 13.6162C13.1587 13.9987 12.915 14.2875 12.615 14.3887C12.2437 14.5125 11.8537 14.5125 11.4825 14.3887C11.1825 14.2912 10.9387 14.0025 10.8375 13.6162L10.68 13.0275C10.3162 11.6625 10.1287 10.2262 10.125 8.77495C10.125 8.21245 10.425 7.7287 10.8412 7.6012C11.6325 7.35745 12.4575 7.35745 13.2487 7.6012C13.665 7.7287 13.9687 8.21245 13.965 8.77495C13.965 10.2225 13.7737 11.6587 13.41 13.0275H13.4175Z" />
    </svg>
  );
};
